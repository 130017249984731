import React from "react";
import { useState } from "react";

import { navigation } from '../../data';

const Navigation = () => {
    const [activeNav, setActiveNav] = useState('#')
    return (
        <nav className="navigation" id="navigation">
            {navigation.map((item, index) =>{
                return <a href={item.href} key={index} onClick={() => setActiveNav(item.href)} className={activeNav === item.href ? 'active' : ''} >{item.icon}</a>
            })}
        </nav>
    )
}

export default Navigation