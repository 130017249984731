import './App.scss';
import React from 'react';
import Header from './components/header/Header'
import Navigation from './components/navigation/Navigation'
import APropos from './components/apropos/Apropos'
import Realisation from './components/realisation/Realisation'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'


const App = () => {
  return (
    <div className='app'>
      <Header />
      <Navigation />
      <APropos />
      <Realisation />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;



