import React from "react";
import CallToAction from './Call-to-action';
import MediaSociaux from './Media-sociaux';
import WOLFOXY from '../../assets/img/logo-svg.svg';

const Header = () => {


    return (
        <header id="header">
            <div className="container header__container">
                <h5>Bienvenue chez</h5>
                <h1>Wolfoxy</h1>
                <h5>Front end developper</h5>
                <CallToAction/>
                <MediaSociaux/>
                <div className="presentation">
                    <img src={WOLFOXY} alt="logo wolfoxy" className="fluide" />
                </div>

                <div className="scroll-icone">
                    <div className="scroll-animation">
                        <span className="scroll"></span>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default Header