import React from "react";

const Projet = ({ item }) => {
    return (
        <div key={item.id} className='card_item'>
            <div className='container-image'>
                <img className='fluide' src={item.image} alt='maquette' />
                <div className="survol">
                    <p className='item__description'>
                    {item.description}
                    </p>
                    <a href={item.lien} target="_blank" rel="noreferrer">voir</a>
                </div>
                
            </div>
            <p className='item__category'>{item.category}</p>
            <h3 className='item__name'>{item.name}</h3>
        </div>
    )
}

export default Projet