import React from "react";
import Projets from './Projets';

const Realisation = () => {
    return (
        <section className="realisation" id="realisation">
            <h2>À la force de mes doigts</h2>

            <div className='container realisation__container'>
                <Projets />
            </div>
                
        </section>
    )
}

export default Realisation