import React from 'react'

import { social } from '../../data';

const MediaSociaux = () => {
  return (
    <div className='header__socials'>
      {social.map((item, index) =>{
        return <a href={item.href} target="_blank" rel="noreferrer" key={index}>{item.icon}</a>
      })}
    </div>
  )
}

export default MediaSociaux