  //  ICONES
  import {
    BsLinkedin,
    BsGithub,
  } from 'react-icons/bs';

  import {
    AiOutlineHome, 
    AiOutlineUser, 
    AiOutlineMessage
  } from 'react-icons/ai'

  import {
    HiOutlineBookOpen
  } from 'react-icons/hi'

  import { 
    FaGraduationCap, 
  } from 'react-icons/fa';

  import { 
    BsBriefcase, 
    BsInfoCircle ,
  } from 'react-icons/bs';

  import { 
    GiStarsStack ,
  } from "react-icons/gi";

  
  // PROJETS IMAGES
  import WebMaldives from './assets/img/projects/maquette_maldives.webp';
  import LogoWolfoxy from './assets/img/projects/logo_Wolfoxy.webp';
  import AppliPhos from './assets/img/projects/appli_phos.webp';
  import WebStreamy from './assets/img/projects/maquette_streamy.webp';
  import WebMetallica from './assets/img/projects/maquette_metallica.webp';
  import LogoBerceau from './assets/img/projects/logo_berceau.webp';
  import AppliBorne from './assets/img/projects/appli_borne.webp';
  import LogoMelodia from './assets/img/projects/logo_Melodia.webp';
  import WebUltimavid from './assets/img/projects/maquette_ultimavid.webp';
  import LogoVAImmo from './assets/img/projects/logo_va_immo.webp';
  import WebRetro from './assets/img/projects/maquette_retro.webp';
  import WebAmusaurus from './assets/img/projects/maquette_amusaurus.webp';
  import Construction from './assets/img/projects/en-construction.webp';
  import WebIuditsa from './assets/img/projects/maquette_iuditsa.webp';
  import WebJmjConstruction from './assets/img/projects/maquette_jmj_construction.webp';
  
  
  // SKILLS IMAGE
  import LOGOHTML from "./assets/img/skills/html.webp";
  import LOGOCSS from "./assets/img/skills/css.webp";
  import LOGOSASS from "./assets/img/skills/sass.webp";
  import LOGOJS from "./assets/img/skills/js.webp";
  import LOGOWP from "./assets/img/skills/wp.webp";
  import LOGOVUE from "./assets/img/skills/vue.webp";
  import LOGOREACT from "./assets/img/skills/react.webp";
  import LOGOADOBE from "./assets/img/skills/adobe.webp";
  

  
  // SOCIAL
  export const social = [
    {
      icon: <BsLinkedin />,
      href: 'https://www.linkedin.com/in/r%C3%A9my-beaumont-71b99b255/',
    },
    {
      icon: <BsGithub />,
      href: 'https://github.com/FrenchMy',
    },
  ];
  // SOCIAL
  export const aPropos = [
    {
      icon: <BsInfoCircle className="icone" />,
      titre: 'Qui suis-je ?',
      etat: 1,
    },
    {
      icon: <BsBriefcase className="icone" />,
      titre: 'Experiences',
      etat: 2,
    },
    {
      icon: <FaGraduationCap className="icone" />,
      titre: 'Éducation',
      etat: 3,
    },
    {
      icon: <GiStarsStack className="icone" />,
      titre: 'Compétences',
      etat: 4,
    },
    
  ];

  // NAVIGATION
  export const navigation = [
    {
      icon: <AiOutlineHome />,
      href: '#',
    },
    {
      icon: <AiOutlineUser />,
      href: '#a-propos',
    },
    {
      icon: <HiOutlineBookOpen />,
      href: '#realisation',
    },
    {
      icon: <AiOutlineMessage />,
      href: '#contact',
    },
  ];
  
  
  // projets
  export const projetsData = [
    {
      id: '1',
      image: WebMaldives,
      name: 'Les Maldives',
      category: 'web developpement',
      description: 'Cégep - Cours pour la validation de l\'outils API',
      lien: 'https://test.wolfoxy.ca/maldives/',
    },
    {
      id: '2',
      image: LogoWolfoxy,
      name: 'Logo Wolfoxy',
      category: 'logo',
      description: 'Travail personnel pour l\'identité visuel de mon entreprise',
      lien: LogoWolfoxy,
    },
    {
      id: '3',
      image: AppliPhos,
      name: 'Phos',
      category: 'application',
      description: 'Cégep - Cours sur l\'idéation d\'une application pour PHOS',
      lien: 'https://xd.adobe.com/embed/a9b57d2d-f8df-48dd-8db9-2feb3a55e5e4-fce6/?fullscreen',
    },
    {
      id: '4',
      image: WebStreamy,
      name: 'Streamy',
      category: 'web developpement',
      description: 'Cégep - Création fictive d\'un site web de video à la demande',
      lien: 'https://test.wolfoxy.ca/streamy/',
    },
    {
      id: '5',
      image: WebMetallica,
      name: 'Metallica',
      category: 'web developpement',
      description: 'Cégep - Cours sur la Single Page Application',
      lien: 'https://test.wolfoxy.ca/metallica/',
    },
    {
      id: '6',
      image: LogoBerceau,
      name: 'Berc\'eau',
      category: 'logo',
      description: 'Idéation de logo pour entreprise Berc\'eau',
      lien: LogoBerceau,
    },
    {
        id: '7',
        image: AppliBorne,
        name: 'Borne',
        category: 'application',
        description: 'Cégep - Cours de validation d\'animation sur Adobe XD',
        lien: 'https://xd.adobe.com/embed/06fdb60e-5912-4ac1-ba36-5918680ea04c-53ae/?fullscreen',
    },
    {
      id: '8',
      image: LogoMelodia,
      name: 'Logo Melodia',
      category: 'logo',
      description: 'Création de logo youtube',
      lien: LogoMelodia,
    },
    {
      id: '9',
      image: WebUltimavid,
      name: 'Ultimavid',
      category: 'web developpement',
      description: 'Cégep - developpement d\'un mini jeu en équipe - Rôle : Programmation',
      lien: 'https://test.wolfoxy.ca/ultimavid/',
    },
    {
      id: '10',
      image: LogoVAImmo,
      name: 'Logo VA Immobilier',
      category: 'logo',
      description: 'Création de logo pour un courtier immobilier',
      lien: LogoVAImmo,
    },
    {
      id: '11',
      image: WebRetro,
      name: 'Retro Hightway',
      category: 'web developpement',
      description: 'Cégep - Cours sur l\'outils Canvas',
      lien: 'https://test.wolfoxy.ca/retrohighway/',
    },
    {
      id: '12',
      image: WebAmusaurus,
      name: 'Amusaurus',
      category: 'application',
      description: 'Cégep - Projet de fin d\'étude, application web - Rôle : programmation',
      lien: 'https://test.wolfoxy.ca/amusaurus',
    },
    {
      id: '13',
      image: WebIuditsa,
      name: 'Institut Universitaire en DI et TSA',
      category: 'web developpement',
      description: 'MANDAT - Création d\'un site web sous WordPress - Rôle : programmation(FrontEnd), intégration',
      lien: 'https://institutditsa.ca/',
    },
    {
      id: '14',
      image: WebJmjConstruction,
      name: 'JMJ Construction',
      category: 'web developpement',
      description: 'MANDAT - Création d\'un site web personnalisé sous WordPress',
      lien: 'https://test.wolfoxy.ca/construction',
    },
  ];
  
  // projets
  export const projetsNav = [
    {
        name: 'tous',
    },
    {
        name: 'web developpement',
    },
    {
        name: 'logo',
    },
    {
        name: 'application',
    },
  ];
  
  // skill
  export const skills = [
    {
      nom: 'HTML',
      image: LOGOHTML,
      alt: 'Logo HTML'
    },
    {
      nom: 'CSS',
      image: LOGOCSS,
      alt: 'Logo CSS'
    },
    {
      nom: 'SASS',
      image: LOGOSASS,
      alt: 'Logo SASS'
    },
    {
      nom: 'JS',
      image: LOGOJS,
      alt: 'Logo JS'
    },
    {
      nom: 'WORDPRESS',
      image: LOGOWP,
      alt: 'Logo Wordpress'
    },
    {
      nom: 'VUE JS',
      image: LOGOVUE,
      alt: 'Logo Vue JS'
    },
    {
      nom: 'REACT JS',
      image: LOGOREACT,
      alt: 'Logo React JS'
    },
    {
      nom: 'ADOBE',
      image: LOGOADOBE,
      alt: 'Logo Adobe'
    },
  ];
  