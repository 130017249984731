import React from "react";
import CV from '../../assets/telechargement/cv.pdf'

const CallToAction = () => {
    return (
        <div className="call-to-action">
            <button className="btn"><a href={CV} download="Curriculum Vitae Beaumont Remy">CV</a></button>
            <button className="btn btn-primary"><a href="#contact">Me joindre</a></button>
        </div>
    )
}

export default CallToAction