import React, { useState, useEffect } from 'react';

// import data
import { projetsData } from '../../data';
import { projetsNav } from '../../data';

// import components
import Projet from './Projet';

const Projets = () => {
  const [item, setItem] = useState({ name: 'tous' });
  const [projets, setProjets] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    // get projets based on item
    if (item.name === 'tous') {
      setProjets(projetsData);
    } else {
      const newProjets = projetsData.filter((projet) => {
        return projet.category.toLowerCase() === item.name;
      });
      setProjets(newProjets);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  return (
    <div>
      {/* projets nav */}
      <nav className='nav__realisation'>
        <ul className='bandeau'>
          {projetsNav.map((item, index) => {
            return (
              <li
                onClick={(e) => {
                  handleClick(e, index);
                }}
                className={`${
                  active === index ? 'active' : ''
                }`}
                key={index}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </nav>
      {/* projets */}
      <section className='realisation__card'>
        {projets.reverse().map((item) => {
          return <Projet item={item} key={item.id} />;
        })}
      </section>
    </div>
  );
};

export default Projets;
