import React, { useState } from "react";

import ME from "../../assets/img/profil.jpg";

import { skills } from '../../data';
import { aPropos } from '../../data';

const APropos = () => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="a-propos" id="a-propos">
            <h2>Me, myself & I</h2>

            <section className="container container__apropos">

                <div className="image">
                    <img src={ME} alt="Rémy Beaumont noir et blanc" className="fluide" />
                </div>

                <div className="description">
                    <div className="apropos__tabs">
                        {aPropos.map((item, index) =>{
                            return <div key={index} className={toggleState === item.etat ? "apropos__button apropos__active" : "apropos__button"} onClick={() => toggleTab(item.etat)}>
                            {item.icon} {item.titre}
                        </div>
                            
                            
                        })}
                    
            
                    </div>

                    <div className="apropos__sections">
                        <div className={toggleState === 1 ? "apropos__content apropos__content-active" : "apropos__content"}>
                            <div className="apropos__info">
                                <p>Bonjour ! Je m'appelle Rémy Beaumont, je suis fraîchement diplômé d'un DEC en Techniques d'Intégration Multimédia au CÉGEP de Matane.</p>
                                <p>De manière generale, je touche à tout dans le web, mais je me suis spécialisé dans le Frontend, soit l'intégration.</p>
                                <p>Je suis originaire de France, et plus précisément l'Île de La Réunion. Avoir plusieurs cultures donne à ma "French touch" un coté plus exotique.</p>
                                <p>L'apprentissage et l'expérience professionnelle sont pour moi des valeurs très importantes, et c'est grâce à mon parcours professionnel plutôt atypique que je suis capable de me démarquer.</p>
                            </div>
                        </div>

                        <div className={toggleState === 2 ? "apropos__content apropos__content-active" : "apropos__content"}>
                            <div className="apropos__experiences">
                                <div className="experience__data">
                                    <div>
                                        <p className="experience__calendrier">
                                            2022 - aujourd'hui
                                        </p>
                                        <h3 className="experience__titre">Développeur Web</h3>
                                        <p className="experience__soustitre">
                                            CIUSSS MCQ - Trois-Rivières (Québec)
                                        </p>
                                        
                                    </div>

                                    <div>
                                        <span className="experience__rond"></span>
                                        <span className="experience__ligne"></span>
                                    </div>
                                </div>
                                <div className="experience__data">
                                    <div></div>

                                    <div>
                                        <span className="experience__rond"></span>
                                        <span className="experience__ligne"></span>
                                    </div>


                                    <div>
                                        <p className="experience__calendrier">
                                            2019 - 2022
                                        </p>
                                        <h3 className="experience__titre">Travaux étudiants</h3>
                                        <p className="experience__soustitre">
                                            Matane (Québec)
                                        </p>
                                        
                                    </div>


                                </div>
                                <div className="experience__data">
                                    <div>
                                        <p className="experience__calendrier">
                                            2016 - 2019
                                        </p>
                                        <h3 className="experience__titre">Conseiller à la vente</h3>
                                        <p className="experience__soustitre">
                                            Atout pc's - Sainte-Marie (île de la Réunion)
                                        </p>
                                        
                                    </div>

                                    <div>
                                        <span className="experience__rond"></span>
                                        <span className="experience__ligne"></span>
                                    </div>
                                </div>
                                <div className="experience__data">
                                    <div></div>

                                    <div>
                                        <span className="experience__rond"></span>
                                        <span className="experience__ligne"></span>
                                    </div>


                                    <div>
                                        <p className="experience__calendrier">
                                            2014 - 2015
                                        </p>
                                        <h3 className="experience__titre">Pratitien SPA - Estheticien</h3>
                                        <p className="experience__soustitre">
                                            École Elegance Gontard - Nice (France)
                                        </p>
                                        
                                    </div>


                                </div>
                                <div className="experience__data">
                                    <div>
                                        <p className="experience__calendrier">
                                            2009 - 2014
                                        </p>
                                        <h3 className="experience__titre">Militaire</h3>
                                        <p className="experience__soustitre">
                                            Service des Essences des Armées - Chalon-sur-Saone (France)
                                        </p>
                                        
                                    </div>

                                    <div>
                                        <span className="experience__rond"></span>
                                        <span className="experience__ligne"></span>
                                    </div>
                                </div>
                            
                            </div>
                        </div>

                        <div className={toggleState === 3 ? "apropos__content apropos__content-active" : "apropos__content"}>
                            <div className="apropos__education">
                                <div className="education__data">
                                    <div>
                                        <p className="education__calendrier">
                                            2019 - 2023
                                        </p>
                                        <h3 className="education__titre">DEC Technique d'Intégration Multimédia</h3>
                                        <p className="education__soustitre">
                                            Cégep de Matane (Québec)
                                        </p>
                                        
                                    </div>

                                    <div>
                                        <span className="education__rond"></span>
                                        <span className="education__ligne"></span>
                                    </div>
                                </div>

                                <div className="education__data">
                                    <div></div>

                                    <div>
                                        <span className="education__rond"></span>
                                        <span className="education__ligne"></span>
                                    </div>


                                    <div>
                                        <p className="education__calendrier">
                                            2014 - 2015
                                        </p>
                                        <h3 className="education__titre">CAP Esthétique cosmétique parfumerie</h3>
                                        <p className="education__soustitre">
                                            École privée Elegance Gontard - Nice (France)
                                        </p>
                                    </div>
                                </div>

                                <div className="education__data">
                                    <div>
                                        <p className="education__calendrier">
                                            2014 - 2015
                                        </p>
                                        <h3 className="education__titre">Diplome SPA praticien</h3>
                                        <p className="education__soustitre">
                                            École privée Elegance Gontard - Nice (France)
                                        </p>
                                    </div>

                                    <div>
                                        <span className="education__rond"></span>
                                        <span className="education__ligne"></span>
                                    </div>
                                </div>

                                <div className="education__data">
                                    <div></div>

                                    <div>
                                        <span className="education__rond"></span>
                                        <span className="education__ligne"></span>
                                    </div>


                                    <div>
                                        <p className="education__calendrier">
                                            2006 - 2008
                                        </p>
                                        <h3 className="education__titre">BACCALAUREAT STI Génie mécanique productique</h3>
                                        <p className="education__soustitre">
                                            Lislet Geoffroy (Île de la Réunion)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            

                        </div>

                        <div className={toggleState === 4 ? "apropos__content apropos__content-active" : "apropos__content"}>
                            <div className="apropos__competences">

                                {skills.map((item, index) =>{
                                    return <div className="boite" key={index}>
                                                <p>{item.nom}</p>
                                                <div className="skill">
                                                    <img src={item.image} alt={item.alt} className="fluide" />
                                                </div>
                                            </div>
                                })}

                            </div>

                        </div>
                    </div>
                </div>

            </section>



        </section>
    )
}

export default APropos