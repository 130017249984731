import React from "react";
import { BsArrowRight } from 'react-icons/bs';
import { FiMail, FiPhone } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
const Contact = () => {
    return (
        <section className="contact" id="contact">
            <h2>Hérauts acceptés</h2>

            <section className="container container__contact">
                <div className="contact__content">
                    <div className="contact__info">
                        <div className="contact__card">
                            <FiMail className="contact__card-icone"/>

                            <h4 className="contact__card-title">Email</h4>
                            <span className="contact__card-data">contact@wolfoxy.ca</span>

                            <a href="mailto:contact@wolfoxy.ca" className="contact__button">Écrivez moi <span><BsArrowRight/></span></a>
                        </div>

                        <div className="contact__card">
                            <FiPhone className="contact__card-icone"/>

                            <h4 className="contact__card-title">Téléphone</h4>
                            <span className="contact__card-data">(418) 429-8746</span>

                            <a href="tel:4184298746" className="contact__button">Appelez moi <span><BsArrowRight/></span></a>
                        </div>

                        <div className="contact__card">
                            <GoLocation className="contact__card-icone"/>

                            <h4 className="contact__card-title">Adresse</h4>
                            <span className="contact__card-data">198 Boulevard Perron <br /> Sainte-Félicité, G0J 2K0</span>

                            <a href="https://goo.gl/maps/SdBV1st8vjqZyinh9" target="_blank" rel="noreferrer" className="contact__button">Trouvez moi <span><BsArrowRight/></span></a>
                        </div>
                    </div>
                </div>
                
            </section>
        </section>
    )
}

export default Contact