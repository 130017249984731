import React from "react";

const Footer = () => {
    return (
        <div id="footer">
            <small>&copy;2023 - Wolfoxy - Tous droits réservé </small>
        </div>
    )
}

export default Footer